import React from 'react'

const Plus = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 6.07971e-07L10 20" stroke="#242424" strokeWidth="2" />
    <path d="M5.20548e-07 9.5L20 9.5" stroke="#242424" strokeWidth="2" />
  </svg>
)

export default Plus
