import { graphql, PageProps } from 'gatsby'
import React from 'react'
import Homepage, {
  AboutSectionData,
  CapabilitiesSectionData,
  CareersSectionData,
  ContactSectionData,
  HeroSectionData,
  SummitSectionData,
} from '../types/pages/homepage'
import { Page, StructuredData } from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import Hero from '../components/homepage/sections/hero'
import About from '../components/homepage/sections/about'
import Capabilities from '../components/homepage/sections/capabilities'
import Summit from '../components/homepage/sections/summit'
import Careers from '../components/homepage/sections/careers'
import Contact from '../components/homepage/sections/contact'

const Index = ({
  data,
}: PageProps<{ prismicHomepage: Homepage }, PageStub>) => {
  /** @type {Homepage} page */
  const page = data.prismicHomepage
  if (!page) {
    return null
  }

  return (
    <>
      <Page page={page}>
        <Hero {...(page.data as HeroSectionData)} />
        <About preloadImage={true} {...(page.data as AboutSectionData)} />
        <Capabilities {...(page.data as CapabilitiesSectionData)} />
        <Summit {...(page.data as SummitSectionData)} />
        <Careers {...(page.data as CareersSectionData)} />
        <Contact {...(page.data as ContactSectionData)} />
        <StructuredData.Organisation />
      </Page>
    </>
  )
}

export const query = graphql`
  query HomepageQuery($id: String) {
    prismicHomepage(id: { eq: $id }) {
      ...Homepage
    }
  }
`

export default Index
