import React from 'react'

const Minus = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 -10 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.6426e-07 1L20 1" stroke="#242424" strokeWidth="2" />
  </svg>
)

export default Minus
