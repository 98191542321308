import React, { useContext } from 'react'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import Button, { ButtonStyle } from '../../button'
import { ContactPanelContext } from '../../../context/contact-panel-context-provider'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import { AboutSectionData } from '../../../types/pages/homepage'
import { linkResolver } from '../../../utils/linkResolver'
import { animator } from '@superrb/gatsby-addons/utils'

const About = ({
  about_title: title,
  about_text: text,
  about_button_label: button_label,
  about_button_link: button_link,
  about_image: image,
  id = 'about',
  reversed = false,
  preloadImage = false,
}: AboutSectionData & {
  id?: string
  reversed?: boolean
  preloadImage?: boolean
}) => {
  const { openContactPanel } = useContext(ContactPanelContext)

  return (
    <section
      className={`about-section ${reversed ? 'about-section--reversed' : ''}`}
      aria-label={title}
      id={id}
      ref={animator}
    >
      <div className="about-section__column about-section__column--content">
        <div className="about-section__content">
          <h2 className="about-section__title">{title}</h2>
          <RichText className="about-section__text" field={text.richText} />

          {button_label && (
            <Button
              {...(button_link
                ? {
                    onClick: openContactPanel,
                  }
                : {
                    href: linkResolver(button_link),
                  })}
              label={button_label}
              buttonStyles={[ButtonStyle.tertiary, ButtonStyle.arrow]}
            />
          )}
        </div>
      </div>

      <div className="about-section__column about-section__column--image">
        <Image
          image={image}
          className="about-section__image"
          layout={ImageLayout.contain}
          sizes="(min-width: 63.75em) 50vw, 100vw"
          preload={preloadImage}
        />
      </div>
    </section>
  )
}

export default About
