import React, { ButtonHTMLAttributes } from 'react'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string
  isCurrent: boolean
}

const MapPin = ({ label, onClick, isCurrent = false, ...props }: Props) => (
  <button className="map-pin" onClick={onClick} {...props}>
    <span
      className="map-pin__tooltip"
      aria-current={isCurrent ? 'location' : false}
      dangerouslySetInnerHTML={{ __html: label.replace(', ', ',\n') }}
    />

    <svg
      width="34"
      height="47"
      viewBox="0 0 34 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="map-pin__icon"
    >
      <circle cx="17" cy="17" r="12" fill="#F6F6F6" />
      <path
        d="M17.0269 24.985C21.4997 24.985 25.1257 21.3592 25.1257 16.8863C25.1257 12.4136 21.4997 8.7876 17.0269 8.7876C12.5542 8.7876 8.92822 12.4135 8.92822 16.8863C8.92822 21.3592 12.5542 24.985 17.0269 24.985Z"
        fill={isCurrent ? '#06dd7e' : '#FF3740'}
        stroke={isCurrent ? '#06dd7e' : '#FF3740'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5542 42.0121C18.8465 44.3355 15.2053 44.3367 13.4908 42.0184C7.81896 34.3494 0 24.3084 0 17.0269C0 7.59873 7.59872 0 17.0269 0C26.3141 0 33.9132 7.59873 33.9132 17.0269C33.9132 24.3064 26.1904 34.3439 20.5542 42.0121ZM17.0269 6.05125C23.0778 6.05125 28.003 10.8358 28.003 16.8867C28.003 22.9376 23.0778 27.8628 17.0269 27.8628C10.9761 27.8628 6.05086 22.9376 6.05086 16.8867C6.05086 10.8358 10.9761 6.05125 17.0269 6.05125Z"
        fill={isCurrent ? '#06dd7e' : '#FF3740'}
      />
    </svg>
  </button>
)

export default MapPin
