import { Map } from 'leaflet'
import React, { useEffect, useState } from 'react'
import { useMap } from 'react-leaflet'
import Button, { ButtonStyle } from '../../../button'
import IconButton from '../../../icon-button'
import Minus from '../../../images/minus.svg'
import Plus from '../../../images/plus.svg'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'

const MapControls = ({
  map,
  closeMap = () => {},
  startTour = () => {},
  tourVisible = false,
}: {
  map: Map
  closeMap: () => void
  startTour: () => void
  tourVisible: boolean
}) => {
  const [zoomOutDisabled, setZoomOutDisabled] = useState<boolean>(false)
  const [zoomInDisabled, setZoomInDisabled] = useState<boolean>(false)
  const isMobile = useIsMobile(true, '372px')

  useEffect(() => {
    if (map) {
      map.addEventListener('zoom', () => {
        setZoomOutDisabled(map.getZoom() === map.options.minZoom)
        setZoomInDisabled(map.getZoom() === map.options.maxZoom)
      })
    }
  }, [map])

  return (
    <nav className="hero-section__controls">
      <div className="hero-section__nav">
        <Button
          buttonStyles={[
            ButtonStyle.tertiary,
            ButtonStyle.back,
            ButtonStyle.border,
          ]}
          label="Exit"
          onClick={closeMap}
        />
        <Button
          buttonStyles={[ButtonStyle.secondary]}
          label={isMobile ? 'Tour' : 'Take the tour'}
          onClick={startTour}
          disabled={tourVisible}
          aria-expanded={tourVisible}
          aria-controls="tour"
        />
      </div>
      <div className="hero-section__zoom">
        <IconButton
          buttonStyles={[ButtonStyle.secondary]}
          label="Zoom Out"
          onClick={() => map.flyTo(map.getCenter(), map.getZoom() - 1)}
          disabled={zoomOutDisabled}
        >
          <Minus />
        </IconButton>
        <IconButton
          buttonStyles={[ButtonStyle.secondary]}
          label="Zoom In"
          onClick={() => map.flyTo(map.getCenter(), map.getZoom() + 1)}
          disabled={zoomInDisabled}
        >
          <Plus />
        </IconButton>
      </div>
    </nav>
  )
}

export default MapControls
