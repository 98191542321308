import React from 'react'
import { Marker } from 'react-leaflet-marker'
import { TourPoint } from '../../../../types/pages/homepage'
import MapPin from './map-pin'

const TourMarker = ({
  point,
  isCurrent = false,
  setCurrent = (point: TourPoint) => {},
  showTour = () => {},
}: {
  point: TourPoint
  isCurrent: boolean
  setCurrent?: (point: TourPoint) => void
  showTour?: () => void
}) => {
  let { x, y, title } = point

  return (
    <Marker position={[y, x]}>
      <MapPin
        label={title}
        onClick={() => {
          showTour()
          setCurrent(point)
        }}
        isCurrent={isCurrent}
      />
    </Marker>
  )
}

export default TourMarker
