import React from 'react'
import {
  AboutSectionData,
  SummitSectionData,
} from '../../../types/pages/homepage'
import About from './about'

const Summit = ({
  summit_title: title,
  summit_text: text,
  summit_button_label: button_label,
  summit_button_link: button_link,
  summit_image: image,
}: SummitSectionData) => (
  <About
    {...({
      about_title: title,
      about_text: text,
      about_button_label: button_label,
      about_button_link: button_link,
      about_image: image,
    } as AboutSectionData)}
    reversed={true}
    id="summit"
  />
)

export default Summit
