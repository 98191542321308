import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import { useMap } from 'react-leaflet'
import { PointOfInterest, TourPoint } from '../../../../types/pages/homepage'
import Close from '../../../images/close.svg'
import Button, { ButtonStyle } from '../../../button'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import IconButton from '../../../icon-button'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import { Map } from 'leaflet'

const FOCUS_ZOOM = 0
const MOBILE_FOCUS_ZOOM = 0

interface TourControlProps {
  visible: boolean
  close: () => void
  points: TourPoint[]
  current?: TourPoint
  setCurrent: (point?: TourPoint) => void
  map: Map
}

const TourControl = ({
  visible = false,
  close = () => {},
  points = [],
  current,
  setCurrent,
  map,
}: TourControlProps) => {
  const [focusZoom, setFocusZoom] = useState<number>(MOBILE_FOCUS_ZOOM)
  const isMobile = useIsMobile()

  useEffect(() => {
    setFocusZoom(isMobile ? MOBILE_FOCUS_ZOOM : FOCUS_ZOOM)
  }, [isMobile])

  useEffect(() => {
    if (!current) {
      return
    }

    const { x, y } = current
    map.flyTo([y, x], focusZoom, { duration: 0.5 })
  }, [current, focusZoom])

  const first = current === points[0]
  const last = current === points[points.length - 1]

  return (
    <nav className="hero-section__tour" id="tour" aria-hidden={!visible}>
      <IconButton
        className="hero-section__tour-close"
        onClick={close}
        buttonStyles={[ButtonStyle.secondary, ButtonStyle.circle]}
        label="Close tour"
      >
        <Close />
      </IconButton>
      <div
        className="hero-section__tour-intro"
        aria-hidden={current !== undefined}
      >
        <h4 className="hero-section__tour-intro-title">Map</h4>
        <p className="hero-section__tour-intro-text">
          Click to explore our facilities.
        </p>

        <ul className="hero-section__tour-points">
          {points.map((point: TourPoint, index) => (
            <li className="hero-section__tour-point" key={index}>
              <Button
                buttonStyles={[ButtonStyle.arrow]}
                label={point.title}
                onClick={() => setCurrent(point, true)}
              />
            </li>
          ))}
        </ul>
      </div>

      <div
        className="hero-section__tour-current"
        aria-hidden={current === undefined}
      >
        <>
          <div className="hero-section__tour-current-content">
            {isMobile && (
              <Image
                className="hero-section__tour-current-image"
                image={current?.image}
                layout={ImageLayout.contain}
              />
            )}

            {'text' in (current || {}) ? (
              <>
                <h4 className="hero-section__tour-current-title">
                  {current?.title}
                </h4>

                <RichText
                  className="hero-section__tour-current-text"
                  field={current?.text.richText}
                />
              </>
            ) : (
              <p className="hero-section__tour-current-text">
                {current?.title}
              </p>
            )}
          </div>

          {current && points.includes(current as TourPoint) && (
            <nav className="hero-section__tour-nav">
              {!first && (
                <Button
                  buttonStyles={[
                    ButtonStyle.tertiary,
                    ButtonStyle.transparent,
                    ButtonStyle.border,
                  ]}
                  className="hero-section__tour-nav-button hero-section__tour-nav-button--next"
                  label={'Previous'}
                  onClick={() => {
                    setCurrent(points[points.indexOf(current) - 1], true)
                  }}
                />
              )}
              <Button
                buttonStyles={
                  last
                    ? [ButtonStyle.secondary]
                    : [
                        ButtonStyle.tertiary,
                        ButtonStyle.transparent,
                        ButtonStyle.border,
                      ]
                }
                className="hero-section__tour-nav-button hero-section__tour-nav-button--next"
                label={last ? 'Finish Tour' : 'Next'}
                onClick={
                  last
                    ? close
                    : () => {
                        setCurrent(points[points.indexOf(current) + 1], true)
                      }
                }
              />
            </nav>
          )}
        </>
      </div>
    </nav>
  )
}

export default TourControl
