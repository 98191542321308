import React from 'react'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import Button, { ButtonStyle } from '../../button'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import { linkResolver } from '../../../utils/linkResolver'
import { CareersSectionData } from '../../../types/pages/homepage'
import { animator } from '@superrb/gatsby-addons/utils'

const Careers = ({
  careers_title: title,
  careers_text: text,
  careers_button_label: button_label,
  careers_button_link: button_link,
  careers_image: image,
}: CareersSectionData) => {
  return (
    <section
      className="careers-section"
      aria-label={title}
      id="careers"
      ref={animator}
    >
      <div className="careers-section__container">
        <div className="careers-section__column careers-section__column--content">
          <div className="careers-section__content">
            <h2 className="careers-section__title">{title}</h2>
            <RichText className="careers-section__text" field={text.richText} />
            <Button
              target={button_link.target}
              href={linkResolver(button_link)}
              label={button_label}
              buttonStyles={[ButtonStyle.secondary, ButtonStyle.arrow]}
            />
          </div>
        </div>

        <div className="careers-section__column careers-section__column--image">
          <Image
            image={image}
            className="careers-section__image"
            layout={ImageLayout.contain}
          />
        </div>
      </div>
    </section>
  )
}

export default Careers
