import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  HeroSectionData,
  PointOfInterest,
  TourPoint,
} from '../../../types/pages/homepage'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import { MapContainer } from 'react-leaflet'
import { MarkerLayer } from 'react-leaflet-marker'
import { CRS, LeafletEvent, Map } from 'leaflet'
import Button, { ButtonStyle } from '../../button'
import TourControl from './hero/tour-control'
import MapControls from './hero/map-controls'
import TourMarker from './hero/tour-marker'
import {
  useEventListener,
  useIsInViewport,
  useIsMobile,
  useLockBodyScroll,
} from '@superrb/gatsby-addons/hooks'
import { ImageOverlay } from '../../leaflet/image-overlay'
import { graphql, useStaticQuery } from 'gatsby'
import Drones from '../../../images/drones.png'
import { usePreload } from '@superrb/gatsby-addons/hooks'
import droneOne from '../../../images/Drone-1.png'
import droneTwo from '../../../images/Drone-2.png'
import droneThree from '../../../images/Drone-3.png'

const drones = [droneOne, droneTwo, droneThree]

const OFFSCREEN_CENTER: [number, number] = [2560, 1458]
const MOBILE_OFFSCREEN_CENTER: [number, number] = [1456, 1920]
const SHORT_MOBILE_OFFSCREEN_CENTER: [number, number] = [1760, 1920]
const CENTER: [number, number] = [2048, 2048]
const MOBILE_CENTER: [number, number] = [1320, 2048]
const SHORT_MOBILE_CENTER: [number, number] = [1860, 2048]
const ZOOM = -1
const MOBILE_ZOOM = -2

const Hero = ({
  hero_title: title,
  hero_text: text,
  hero_button_label: button_label,
  hero_tour_points: tour_points = [],
  hero_points_of_interest: points_of_interest = [],
}: HeroSectionData) => {
  // State
  const [mapVisible, setMapVisible] = useState<boolean>(false)
  const [tourVisible, setTourVisible] = useState<boolean>(false)
  const [center, setCenter] = useState<[number, number]>()
  const [offscreenCenter, setOffscreenCenter] = useState<[number, number]>()
  const [zoom, setZoom] = useState<number>()
  const [frontendReady, setFrontendReady] = useState<boolean>(false)
  const [currentTourPoint, setCurrentTourPoint] = useState<
    PointOfInterest | undefined
  >(undefined)

  // Refs
  const map = useRef<Map>() as MutableRefObject<Map>

  // Hooks
  const isMobile = useIsMobile(true)
  const { isInViewport, setRef } = useIsInViewport(false)

  // Queries
  const data = useStaticQuery(graphql`
    query HeroImageQuery {
      file(relativePath: { eq: "ziggurat-03-m.png" }) {
        childImageSharp {
          fluid(
            srcSetBreakpoints: [
              25
              750
              1080
              1366
              1920
              2560
              3840
              4096
              5120
            ]
          ) {
            base64
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
      drones: allFile(
        filter: {
          relativePath: { in: ["Drone-1.png", "Drone-2.png", "Drone-3.png"] }
        }
        sort: { fields: relativePath }
      ) {
        nodes {
          childImageSharp {
            fluid(srcSetBreakpoints: [25, 45, 90, 180, 270]) {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  `)

  const srcSet =
    data.file?.childImageSharp?.fluid?.srcSetWebp ||
    data.file?.childImageSharp?.fluid?.srcSet
  const src = srcSet.split(',')[0].split(' ')[0]

  const drones = data.drones?.nodes?.map((node) => node.childImageSharp.fluid)

  // Hooks
  usePreload(src, srcSet)
  useLockBodyScroll(mapVisible)

  // Callbacks
  const openMap = useCallback(() => {
    setMapVisible(true)

    if (map.current) {
      map.current.panTo(center, { duration: 0.5 })
    }
  }, [setMapVisible, center, zoom])

  const closeMap = useCallback(() => {
    endTour(false)

    if (map.current) {
      if (map.current.getZoom() !== zoom) {
        map.current.flyTo(offscreenCenter, zoom, { duration: 0.5 })
      } else {
        map.current.panTo(offscreenCenter, { duration: 0.5 })
      }
    }

    setMapVisible(false)
  }, [setMapVisible, offscreenCenter, zoom])

  const showTour = useCallback(() => {
    setTourVisible(true)
  }, [setTourVisible])

  const startTour = useCallback(() => {
    showTour()
    setCurrentTourPoint(tour_points[0])
  }, [])

  const endTour = useCallback(
    (move: boolean = true) => {
      setTourVisible(false)

      setTimeout(() => {
        setCurrentTourPoint(undefined)

        if (move && map.current) {
          map.current.flyTo(center, zoom, { duration: 0.5 })
        }
      }, 500)
    },
    [setTourVisible, setCurrentTourPoint, center, zoom, map.current],
  )

  const setDimensions = useCallback(() => {
    console.log({
      height: window.matchMedia('(min-height: 46em)').matches,
      isMobile,
      isInViewport,
      center,
      offscreenCenter,
      zoom,
      mapVisible,
      srcSet,
    })
    setCenter(
      isMobile
        ? window.matchMedia('(min-height: 46em)').matches
          ? MOBILE_CENTER
          : SHORT_MOBILE_CENTER
        : CENTER,
    )
    setOffscreenCenter(
      isMobile
        ? window.matchMedia('(min-height: 46em)').matches
          ? MOBILE_OFFSCREEN_CENTER
          : SHORT_MOBILE_OFFSCREEN_CENTER
        : OFFSCREEN_CENTER,
    )
    setZoom(isMobile ? MOBILE_ZOOM : ZOOM)
  }, [setCenter, setOffscreenCenter, setZoom, isMobile])

  const goTo = useCallback(
    (point: TourPoint) => {
      setCurrentTourPoint(point)
    },
    [setCurrentTourPoint],
  )

  // Effects
  useEffect(() => {
    setDimensions()
  }, [isInViewport, isMobile, srcSet])

  useEffect(() => {
    setFrontendReady(!!(center && offscreenCenter && zoom))
  }, [center, offscreenCenter, zoom])

  useEffect(() => {
    if (!isInViewport) {
      closeMap()
    }
  }, [isInViewport, closeMap])

  useEffect(() => {
    if (mapVisible) {
      window.scrollTo(0, 0)
    }
  }, [mapVisible])

  // Event Listeners
  useEventListener(
    'click',
    closeMap,
    undefined,
    typeof document !== 'undefined'
      ? document.getElementById('logo')
      : undefined,
  )

  useEventListener('resize', setDimensions)

  return (
    <section
      className={`hero-section ${mapVisible ? 'hero-section--show-map' : ''}`}
      aria-label={title}
      id="hero"
      ref={setRef}
    >
      <div className="hero-section__drones">
        {drones.map(({ srcWebp, srcSetWebp }) => (
          <Image
            image={{
              alt: 'Drones',
              fluid: { src: srcWebp, srcSet: srcSetWebp },
            }}
            className="hero-section__drone"
            preload={true}
            sizes="9.5vw"
          />
        ))}
      </div>
      <div className="hero-section__content">
        <h1 className="hero-section__title">{title}</h1>
        <RichText className="hero-section__text" field={text.richText} />

        <Button
          buttonStyles={[ButtonStyle.primary, ButtonStyle.arrow]}
          className="hero-section__button"
          onClick={openMap}
          label={button_label}
        />
      </div>

      <div className="hero-section__map">
        {frontendReady && (
          <>
            <button className="hero-section__overlay-trigger" onClick={openMap}>
              <span className="screenreader-text">{button_label}</span>
            </button>

            <MapContainer
              id="map"
              className="hero-section__map-container"
              crs={CRS.Simple}
              bounds={[
                [0, 0],
                [3072, 4096],
              ]}
              center={offscreenCenter}
              minZoom={-3}
              maxZoom={1}
              zoom={zoom}
              zoomSnap={0}
              scrollWheelZoom={false}
              zoomControl={false}
              ref={map}
            >
              <ImageOverlay
                bounds={[
                  [0, 0],
                  [3072, 4096],
                ]}
                url={src}
                eventHandlers={{
                  load: (event: LeafletEvent) => {
                    if (!event.currentTarget?.srcset) {
                      event.currentTarget.srcset =
                        event.currentTarget?.dataset?.srcset
                      delete event.currentTarget?.dataset?.srcset
                    }
                  },
                }}
                srcset={srcSet}
                sizes="100vw"
              />

              <MarkerLayer>
                {tour_points.map((point, index) => (
                  <TourMarker
                    key={index}
                    point={point}
                    isCurrent={currentTourPoint === point}
                    setCurrent={goTo}
                    showTour={showTour}
                  />
                ))}

                {/* {points_of_interest.map((point, index) => (
                  <TourMarker
                    key={index}
                    vepoint={point}
                    isCurrent={currentTourPoint === point}
                    setCurrent={goTo}
                    showTour={showTour}
                  />
                ))} */}
              </MarkerLayer>
            </MapContainer>

            <MapControls
              closeMap={closeMap}
              startTour={startTour}
              tourVisible={tourVisible}
              map={map.current}
            />
            <TourControl
              visible={tourVisible}
              close={endTour}
              current={currentTourPoint}
              setCurrent={goTo}
              points={tour_points}
              map={map.current}
            />
          </>
        )}
      </div>
    </section>
  )
}

export default Hero
