import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { CapabilitiesSectionData } from '../../../types/pages/homepage'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import {
  useDraggableScroll,
  useEventListener,
  useIsInViewport,
  useIsMobile,
} from '@superrb/gatsby-addons/hooks'
import IconButton from '../../icon-button'
import { ButtonStyle } from '../../button'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import { animator } from '@superrb/gatsby-addons/utils'

const Capabilities = ({
  capabilities_title: title,
  capabilities_text: text,
  capabilities_capabilities: capabilities,
}: CapabilitiesSectionData) => {
  const isMobile = useIsMobile(true)
  const list = useRef<HTMLUListElement>() as MutableRefObject<HTMLUListElement>
  const { events } = useDraggableScroll(list, {
    className: 'capabilities-section__capabilities',
  })

  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [nextVisible, setNextVisible] = useState<boolean>(true)
  const [previousVisible, setPreviousVisible] = useState<boolean>(false)
  const { isInViewport: isLastInViewport, setRef: setLastItemRef } =
    useIsInViewport(false, '-200px -200px')

  useEffect(() => {
    setPreviousVisible(currentSlide !== 0)
  }, [currentSlide])

  useEffect(() => {
    setNextVisible(!isLastInViewport)
  }, [isLastInViewport])

  const handleScroll = useCallback(() => {
    if (!list.current) {
      return
    }

    const items = list.current.children
    for (const child of items[Symbol.iterator]()) {
      const box = child.getBoundingClientRect()
      if (box.left > -50) {
        setCurrentSlide(Array.prototype.indexOf.call(items, child))
        return
      }
    }

    setCurrentSlide(0)
  }, [setCurrentSlide, list.current])

  const goTo = useCallback(
    (index: number) => {
      if (!list.current) {
        return
      }

      list.current.scrollTo({
        left: list.current.children[index].offsetLeft,
        behavior: 'smooth',
      })
    },
    [list.current],
  )

  return (
    <section
      className="capabilities-section"
      aria-label={title}
      id="services"
      ref={animator}
    >
      <div className="capabilities-section__content">
        <h2 className="capabilities-section__title">{title}</h2>
        <RichText
          className="capabilities-section__text"
          field={text.richText}
        />
      </div>

      <ul
        className="capabilities-section__capabilities"
        {...events}
        ref={list}
        onScroll={handleScroll}
      >
        {capabilities.map(({ title, text, image }, index) => (
          <li
            className="capabilities-section__capability"
            key={index}
            aria-current={index === currentSlide ? 'location' : false}
            ref={index === capabilities.length - 1 ? setLastItemRef : null}
          >
            <h3 className="capabilities-section__capability-title">{title}</h3>
            <Image
              className="capabilities-section__capability-image"
              image={image}
              layout={ImageLayout.contain}
            />
            <RichText
              className="capabilities-section__capability-text"
              field={text.richText}
            />
          </li>
        ))}
      </ul>

      {!isMobile && (
        <>
          {previousVisible && (
            <IconButton
              className="capabilities-section__previous"
              buttonStyles={[
                ButtonStyle.secondary,
                ButtonStyle.back,
                ButtonStyle.circle,
                ButtonStyle.border,
              ]}
              onClick={() => goTo(currentSlide - 1)}
              label="Previous slide"
            />
          )}
          {nextVisible && (
            <IconButton
              className="capabilities-section__next"
              buttonStyles={[
                ButtonStyle.secondary,
                ButtonStyle.arrow,
                ButtonStyle.circle,
                ButtonStyle.border,
              ]}
              onClick={() => goTo(currentSlide + 1)}
              label="Next slide"
            />
          )}
        </>
      )}
    </section>
  )
}

export default Capabilities
