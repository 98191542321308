import React, { MutableRefObject, useContext, useRef } from 'react'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import Button, { ButtonStyle } from '../../button'
import { ContactPanelContext } from '../../../context/contact-panel-context-provider'
import { ContactSectionData } from '../../../types/pages/homepage'
import images from './contact/images'
import { animator } from '@superrb/gatsby-addons/utils'
import { useIsInViewport, useParallax } from '@superrb/gatsby-addons/hooks'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import ContactSectionImages from './contact/images'

const Contact = ({
  contact_title: title,
  contact_text: text,
  contact_button_label: button_label,
}: ContactSectionData) => {
  const { openContactPanel } = useContext(ContactPanelContext)
  const { isInViewport, setRef } = useIsInViewport(false)

  const refHandler = (ref: HTMLElement) => {
    animator(ref as HTMLElement)
    setRef(ref)
  }

  return (
    <section
      className="contact-section"
      aria-label={title}
      id="contact"
      ref={refHandler}
    >
      <ContactSectionImages parallax={isInViewport} />

      <div className="contact-section__content">
        <h2 className="contact-section__title">{title}</h2>
        <RichText className="contact-section__text" field={text.richText} />
        <Button
          onClick={openContactPanel}
          label={button_label}
          buttonStyles={[ButtonStyle.tertiary, ButtonStyle.arrow]}
        />
      </div>
    </section>
  )
}

export default Contact
